import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AltaSolicitudesService, OptionsService, MiFielService, ReportesPDFService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import swal2 from 'sweetalert2';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { exit, getMaxListeners } from 'process';
import { browser } from 'protractor';
declare var $;

@Component({
  selector: 'app-estatussolicitudes',
  templateUrl: './estatussolicitudes.component.html',
  styles: []
})
export class EstatusSolicitudesComponent implements OnInit {
  accesoupdateatta = false;
  showModalatta: boolean;
  uploadForm: FormGroup;
  showModalidsol: boolean;
  url: any;
  file: any;
  datosdetalles: any[] = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatussolicitudes: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;

  constructor(private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              public _optionsservice: OptionsService,
              public _mifiel: MiFielService,
              public reportespdf: ReportesPDFService,
              private formBuilder: FormBuilder, private httpClient: HttpClient, 
              private _firestorage: AngularFireStorage,
              public _solicitudesservice: AltaSolicitudesService) {}

              show(datarow) {
                this.showModal = true; // Show-Hide Modal Check
               // console.log(datarow);
                this.idsolicitud = datarow.id;
                this.foliosolicitud = datarow.folio;
              }
              hide() {
                this.showModal = false;
                this.idsolicitud = '';
                this.foliosolicitud = '';
                this.ngOnInit();
              }

  ngOnInit() {
    this.showModalidsol = false;
    this.accesoupdateatta = false
    let correoparaupdate = localStorage.getItem('emailuser');
    let idu = localStorage.getItem('id');
    this._solicitudesservice.getPermisosUsuario(idu).subscribe( resp => {
    if (resp[0] === 'SI') {
      this._solicitudesservice.getPermisoParaAtta().subscribe(resp2 => {
      if (resp2[0].includes(correoparaupdate)) {
        this.accesoupdateatta = true
      }
      })
    }
    } )
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
   // this.getAcceso('/estatussolicitudes');

    const estatus: any = document.getElementById('estatus');

    estatus.options[estatus.selectedIndex].value = 0;

    this.selectedSol = [];

    this.idu = localStorage.getItem('id'); 

    this._solicitudesservice.getSolicitudesxusuario(this.idu).subscribe( resp => { 
      this.solicitudes = resp; 
      swal2.close();
     } );
    
    this._solicitudesservice.getUsuariosFinanciero().subscribe( resp => { this.usuarios = resp;} );

    this._solicitudesservice.getEstatusFacturas().subscribe( resp => { 
      this.estatussolicitudes = resp;
    } );

    this.cols = [

    { field: 'fecha_factura', header: 'Fecha Factura' },
    { field: 'fecha_operacion', header: 'Fecha Operacion' },
    { field: 'fecha_vencimiento', header: 'Fecha Vencimiento' },
    { field: 'total_operar', header: 'Total operado' },
    { field: 'moneda', header: 'Moneda' },
    { field: 'usuario', header: 'Usuario' },
    { field: 'status', header: 'Estatus' },
    { field: 'cadena', header: 'Cadena' },
    { field: 'proovedor', header: 'Proveedor' },
   // { field: 'attached', header: 'Documento de sesion de derechos' }
  ];
    
  }

  showatt() {
    this.showModalatta = true; // Show-Hide Modal Check
  }
  hideatt() {
    this.showModalatta = false;
  }

  showattidsol() {
    this.showModalidsol = true; // Show-Hide Modal Check
  }
  hideattidsol() {
    this.showModalidsol = false;
  }

  hideds() {
    this.showmodalds = false;
  }
   
   imageChoice(theEventFromHtml) {
    this.file = theEventFromHtml.target.files[0]

  }

  getAcceso(url) {
    let tieneacceso = false;
    this._optionsservice.getOptionsxUsuario(localStorage.getItem('id')).subscribe(resp2 => {
      // tslint:disable-next-line: forin
      for (const j in resp2 ) {
          if ( resp2[j].url === url ) {
            tieneacceso = true;
            break;
          }
        }
      if (!tieneacceso) {
        this.router.navigate(['/accesodenegado']);
      }
    });
  }

  mustradetallessolicitud(id) {

    this.showmodalds = true;
    this._solicitudesservice.getdetallessolicitudes(id).subscribe( resp => {this.datosdetalles = resp  } 
    ,(err) => {
      swal2.fire(
        'Ocurrio un error al consultar los detalles',
        '',
        'error'
        );
    })
  }

  async guardarCambios() {

    let idu = (document.getElementById('usuario')as HTMLInputElement).value;
    let estatusval: any = (document.getElementById('estatus')as HTMLInputElement).value;
    
    if ( this.selectedSol.length === 0 ) {

      swal2.fire(
        'Debe seleccionar al menos una solicitud',
        '',
        'error'
        );

    } else if ( estatusval === '0' && idu === '' ) {

      swal2.fire(
        'Debe seleccionar un estatus o un usuario',
        '',
        'error'
        );

    } else {
      swal2.showLoading();

      // tslint:disable-next-line: forin
      let ids;
      let params = {
        token: '',
        secret_key: '',
        request: {}
      };
      let flag = true;
      if(estatusval === 'CANCELADA'){
        await swal2.fire({
          text: "¿Desea que las facturas vuelvan a estar disponibles para descuento?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            showCloseButton: true,
            allowOutsideClick: false
        }).then((result) => {
          if(result.value){
            params['invoices_available'] = 'true';
          }else if(result.dismiss == swal2.DismissReason.cancel){
            params['invoices_available'] = 'false';
          }else if(result.dismiss == swal2.DismissReason.close){
            flag = false;
          }
        })
          
      }
      if(flag){
        if (estatusval !== '0' && idu !== '') {
          for (const prop in this.selectedSol) {
            ids = this.selectedSol[prop].id;
            params['request']['user_id'] = idu;
            params['request']['status'] = estatusval;
            this.updateSolicitudes(ids, params);
        }
      
        } else if (estatusval !== '0' && idu === '') {
          for (const prop in this.selectedSol) {
            ids = this.selectedSol[prop].id;
            params['request']['status'] = estatusval;
            this.updateSolicitudes(ids, params);
        }
        
        } else if (estatusval === '0' && idu !== '') {
          for (const prop in this.selectedSol) {
            ids = this.selectedSol[prop].id;
            params['request']['user_id'] = idu;
            this.updateSolicitudes(ids, params);
          } 
        }
      }
    }

  }

  updateSolicitudes(ids, params){
    this._solicitudesservice.updateSolicitudes(ids, params).subscribe(() => {
      swal2.fire({
        title: 'Modificacion de Solicitudes',
        text: 'Exitosa',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          location.reload();
        }
      } );
    });
  }

  actualizaattach() {

    if (this.selectedSol.length < 1) {
      swal2.fire(
        'Debe seleccionar al menos una solicitud',
        '',
        'error'
        );
    } else {
      // tslint:disable-next-line: forin
      for (const prop in this.selectedSol) {

        const ids = this.selectedSol[prop].id;

        const params = {
          token: '',
          secret_key: '',
          attached: this.selectedSol[prop].attached
      };

        this._solicitudesservice.updateSolicitudes(ids, params).subscribe( );
      }
      // location.reload();
      swal2.fire({
        title: 'Los documentos seleccionados se actualizaron',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
          window.location.reload();
        }

      } );
    }
  }

  subirdoc( sol ) {
    const file = sol.target.files[0];
    this.carpeta = this.foliosolicitud;
    this.nombrearchivosubido = this.foliosolicitud;
    const filepath = `${this.foliosolicitud}/${this.foliosolicitud}`;
    const fileRef = this._firestorage.ref(filepath);
    const task = this._firestorage.upload(filepath, file);
    this.uploadProgress = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => this.uploadURL = fileRef.getDownloadURL())
    ).subscribe();
   // console.log(this.uploadURL);
  }

  subirurl(uploadURL) {
    const params = {
      token: '',
      secret_key: '',
      attached: ''
  };
    let urll = '';
    const storage = firebase.storage();
    storage.ref(`${this.carpeta}/${this.nombrearchivosubido}`).getDownloadURL()
    .then((url) => {
    // Do something with the URL ...
   // console.log(url);
    params.attached = url;
   // console.log(params);
   // console.log(this.idsolicitud);
    this._solicitudesservice.updateSolicitudes(this.idsolicitud, params).subscribe( resp => { swal2.fire({
      title: 'El archivo se guardo',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        window.location.reload();
      }

    } ); }, (err) => {
      console.log(err);
      swal2.fire({
        title: 'Ocurrio un error',
        text: err.error.errors[0],
        icon: 'error',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          location.reload();
        }
      } );
     } );
  });
   // console.log(urll);
  }

  borraArchivo(rowData) {

    swal2.fire({
      title: 'Desea borrar el archivo?',
        text: rowData.folio,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        const params = {
          token: '',
          secret_key: '',
          attached: ''
      };
        // Create a reference to the file to delete
        const storage = firebase.storage();
       // console.log(rowData.folio);
       // storage.ref(`SF1601017342/`).delete();
        const task = this._firestorage.storage.ref(`${rowData.folio}/`);
        task.listAll().then(function(result) {
          result.items.forEach(function(referencia) {
            // And finally display them
           // this.displayImage(referencia)
           referencia.delete();
    
        });
         });
    
        params.attached = null;
        this._solicitudesservice.updateSolicitudes(rowData.id, params).subscribe( resp => { swal2.fire({
            title: 'El archivo se borro',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
      
            if ( res.value ) {
              window.location.reload();
            }
      
          } ); }, (err) => {
            console.log(err);
            swal2.fire({
              title: 'Ocurrio un error',
              text: err.error.errors[0],
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res => {
              if ( res.value ) {
                location.reload();
              }
            } );
           } );
      }
    });

  }

  upload(event) {

    const file = event.target.files[0];

    const randomId = Math.random().toString(36).substring(2);
    const filepath = `facturas/${event.target.files[0].name}`;
    const fileRef = this._firestorage.ref(filepath);
    const task = this._firestorage.upload(filepath, file);
    this.uploadProgress = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => this.uploadURL = fileRef.getDownloadURL())
    ).subscribe();
  }

  confirmaattached() {
    let ids: any = (document.getElementById('idsol')as HTMLInputElement).value;
    let atta: any = (document.getElementById('att')as HTMLInputElement).value;
    if (ids === '' || atta === '') {
      swal2.fire(
        'No puede dejar un campo en blanco',
        '',
        'warning'
      ).then ( () => { this.accesoupdateatta = false; this.ngOnInit()});
    } else {
      swal2.fire({
        title: 'Desea confirmar los datos',
        text: '',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          swal2.fire({
            title: 'Procesando los datos',
            text:  '',
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            onOpen: () => {
              swal2.showLoading();
            }
          })
          this.reportespdf.subirdocFactordirectointerfaz( ids, atta ).subscribe( resp => {console.log(resp)
          swal2.fire(
            'Se procesaron los datos correctamente',
            'favor de abrir la consola para ver la respuesta sin cerrar este mensaje',
            'success'
          ). then ( () => this.ngOnInit() )
          } , (err) => (console.log(err),
          swal2.fire(
            'Hubo un error al procesar los datos',
            'favor de abrir la consola para ver la respuesta sin cerrar este mensaje',
            'warning'
          ). then ( () => this.ngOnInit() )
          ) )
          
        } else {
          this.ngOnInit();
        }
      } );
    }
  }

  reporteporid() {
    let idsolicitud = (document.getElementById('idsolicitud')as HTMLInputElement).value;
    let firmantes = [];
    let firmantesreporte = [];
    let firmantesreportenombres = '';
    let facturasReporte = [];
     this.reportespdf.getinfosolicitudxid(idsolicitud).subscribe(respsol => {
      this._solicitudesservice.getCadenaProveedor(respsol.data.attributes.user_id).subscribe( respprov => {
        if ( respprov.length > 0 ) {
          const data = {
            nombrecadena: respprov[0].cadena,
            nombreproveedor: respprov[0].proveedor,
            companyid: respprov[0].company_id,
            supplierid: respprov[0].supplier_id
          }
          for ( const prop in respsol.data.relations.invoices ) {
            facturasReporte[prop] = [data.nombreproveedor, respsol.data.attributes.folio, respsol.data.relations.invoices[prop].attributes.total_used, respsol.data.relations.invoices[prop].attributes.entry_date, respsol.data.relations.invoices[prop].attributes.due_date];
          } 
          if (respprov[0].supplier_id !== 0) {                                                                    
            this._solicitudesservice.getSignatories(respprov[0].supplier_id).subscribe(resp2 => { firmantes = resp2;
              // tslint:disable-next-line: forin
              for (const prop in firmantes) {
                firmantesreporte[prop] = firmantes[prop].firmante;
              }
              firmantesreportenombres = firmantesreporte.join(' / ');
              const  data2 = {
                nombrecadena: respprov[0].cadena,
                startdatesuplier: '',
                direccioncompany: '',
                nombreproveedor: respprov[0].proveedor,
                firmantesreportenombres: firmantesreportenombres,
                } 
              this.reportespdf.cesion_derechos_factor_async(facturasReporte, respsol.data.attributes.id, respsol.data.attributes.folio, data2 );
            });
          } else {
            this._solicitudesservice.getSignatoriesw(respprov[0].company_id).subscribe(resp2 => { firmantes = resp2;
              // tslint:disable-next-line: forin
              for (const prop in firmantes) {
                firmantesreporte[prop] = firmantes[prop].firmante;
              }
              firmantesreportenombres = firmantesreporte.join(' / ');
              const  data2 = {
                nombrecadena: respprov[0].cadena,
                startdatesuplier: '',
                direccioncompany: '',
                nombreproveedor: respprov[0].proveedor,
                firmantesreportenombres: firmantesreportenombres,
                }
                this.reportespdf.cesion_derechos_factor_async(facturasReporte, respsol.data.attributes.id, respsol.data.attributes.folio, data2 );
          });
          }
        } else {
          swal2.fire('No se encontro una cadena configurada')
          .then( () => this.ngOnInit() );
        }
      } )
      }, (err) => {console.log(err)
      swal2.fire('Ocurrio un error o no existe el ID capturado')
      .then( () => this.ngOnInit() )
      } )
    }

}







